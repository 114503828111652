import styled from "styled-components";
import Box from "./box";

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(217, 68, 48, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8e3b31;
  position: absolute;
  top: 0;
  left: 0;
  padding: 24px;
  box-sizing: border-box;
  text-align: center;
`;

const WidgetError = () => (
  <Box>
    <Container>Uh oh, something went wrong.</Container>
  </Box>
);

export default WidgetError;
