import Sidebar from "../sidebar";
import Header from "../header";
import styled, { css } from "styled-components";

const Box = styled.div<{ split: "vertical" | "horizontal" }>`
  display: flex;
  flex-direction: ${(props) =>
    props.split === "horizontal" ? "row" : "column"};
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const Cell = styled.div<{ width?: string; height?: string; padding?: string }>`
  ${(props) =>
    props.padding
      ? css`
          padding: ${props.padding};
          overflow: auto;
        `
      : ""}
  width: ${(props) => props.width};
  height: ${(props) => (props.height ? props.height : "100%")};
  flex-basis: 1;
  flex-grow: ${(props) => (props.width ? "0" : "1")};
  box-sizing: border-box;
`;

const defaultLayout = ({ children }: { children: React.ReactNode }) => (
  <Box split={"vertical"}>
    <Cell height={"42px"}>
      <Header />
    </Cell>
    <Box split={"horizontal"}>
      <Cell width={"180px"}>
        <Sidebar />
      </Cell>
      <Cell padding={"16px"}>{children}</Cell>
    </Box>
  </Box>
);

export default defaultLayout;
