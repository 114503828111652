import WidgetSize from "../WidgetSize";

const getGridTemplateAreas = ({
  idSize,
  columns = 3,
  resize,
}: {
  idSize: { id: string; size: WidgetSize }[];
  columns?: number;
  resize?: number;
}) => {
  const sizeNum: Record<WidgetSize, number> = {
    SMALL: 1,
    MEDIUM: resize ? resize : columns >= 2 ? 2 : columns,
    LARGE: resize ? resize : columns >= 3 ? 3 : columns,
  };

  let result = "";

  let tmp: string[] = [""];

  for (let index = 0; index < idSize.length; index++) {
    const current = idSize[index];
    const currentSize = sizeNum[current.size];

    const row = tmp[tmp.length - 1];
    const available = columns - (row ? row.split(" ").length : 0);

    let addToRow = "";

    if (currentSize <= available) {
      addToRow = Array(currentSize).fill(`c${current.id}`).join(" ");
      tmp[tmp.length - 1] = `${row ? row + " " : ""}${addToRow}`;

      if (idSize.length - 1 === index && available - currentSize)
        tmp[tmp.length - 1] += ` ${Array(available - currentSize)
          .fill(".")
          .join(" ")}`;
    } else {
      addToRow = Array(available).fill(`.`).join(" ");
      if (addToRow) tmp[tmp.length - 1] = `${row ? row + " " : ""}${addToRow}`;
      tmp.push("");
      index--;
    }
  }

  result = `"${tmp.filter((x) => x).join('" "')}"`;

  return result;
};

export default getGridTemplateAreas;
