import { ReactNode } from "react";
import styled from "styled-components";
import { foreground, title } from "../../ui/theme";
import ui from "../../ui";

const { space: Space } = ui;

const Container = styled.div<{ minHeight?: number }>`
  width: 100%;
  height: 100%;
  background-color: ${foreground};
  box-shadow: 0 42px 84px rgb(36 43 75 / 7%);
  border-radius: 12px;
  padding: 16px 24px 24px;
  box-sizing: border-box;
  min-height: ${(props) => (props.minHeight ? props.minHeight : 230)}px;
  position: relative;
  overflow: hidden;
`;

const Title = styled.h3`
  color: ${title};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 16px;
`;

const Separator = styled.div`
  border: 1px solid #e0e0e0;
  width: 100%;
  position: absolute;
  left: 0;
  margin-bottom: 12px;
`;

const Box = ({
  title,
  minHeight,
  children,
}: {
  title?: string;
  minHeight?: number;
  children?: ReactNode;
}) => (
  <Container minHeight={minHeight}>
    {title ? (
      <>
        <Title>{title}</Title>
        <Separator />
        <Space height={23} />
      </>
    ) : null}
    {children}
  </Container>
);
export default Box;
