import { Link } from "react-router-dom";
import styled from "styled-components";
import { text } from "../../theme";
import { Route } from ".././../../routes";

const A = styled(Link)`
  text-decoration: none;
  color: ${text};
  text-weight: bold;
  font-size: 14px;
  padding: 8px 0;
`;

const item = ({
  name,
  slug,
  children,
}: {
  name: string;
  slug: string;
  children?: Route[] | null;
}) => <A to={`/${slug}`}>{name}</A>;

export default item;
