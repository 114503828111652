import { Link, RouteComponentProps } from "react-router-dom";

const teams = ({ match: { url } }: RouteComponentProps<any>) => (
  <>
    <p>Teams page</p>
    <p>
      View stuff <Link to={`${url}/rr`}>here</Link>
    </p>
    <p>
      Broken link <Link to={`/broken`}>here</Link>
    </p>
  </>
);

export default teams;
