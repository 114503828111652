import styled from "styled-components";

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 16px;
  box-sizing: border-box;
`;

const Title = styled.h2`
  font-weight: 400;
  text-align: center;
  margin: 0;
`;

const pageNotFound = () => (
  <Box>
    <Title>Uh oh, page not found.</Title>
  </Box>
);

export default pageNotFound;
