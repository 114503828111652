import { Line as RCLine } from "react-chartjs-2";

const data = {
  labels: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
  ],
  datasets: [
    {
      label: "Avg stress score",
      data: [
        60,
        65,
        66,
        67,
        68,
        50,
        70,
        73,
        75,
        79,
        67,
        70,
        70,
        76,
        77,
        73,
        77,
        76,
        73,
        77,
        83,
      ],
      fill: false,
      borderColor: "#4e3d85",
      pointHitRadius: 8,
      lineTension: 0,
    },
  ],
};

const Line = () => (
  <RCLine
    data={data}
    options={{
      scales: { yAxes: [{ display: false }], xAxes: [{ display: false }] },
      elements: { point: { radius: 0 }, line: { borderWidth: 1 } },
      legend: {
        display: false,
      },
      maintainAspectRatio: false,
      responsive: true,
    }}
  />
);

export default Line;
