import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

const errorLink = onError(({ networkError, response, graphQLErrors }) => {});

const authLink = (token: string) => setContext((_, { headers }) => ({}));

const httpLink = createHttpLink({});

const cache = new InMemoryCache();

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([errorLink, authLink("").concat(httpLink)]),
});

export default client;
