import styled from "styled-components";
import Box from "../../box";
import { text } from "../../../../ui/theme";
import ui from "../../../../ui/index";
import Line from "../../line";

const { bar: Bar, space: Space } = ui;

const ScoreBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const Score = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  margin-bottom: 8px;
  margin-top: 0;
  color: #333333;
`;

const UpDown = styled.span`
  color: ${text};
  opacity: 0.4;
  font-size: 13px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: block;
`;

const ChartContainer = styled.div`
  height: 68px;
`;

const performanceScore = () => (
  <Box title="Performance Score">
    <ScoreBox>
      <Score>83</Score>
      <div>
        <UpDown>↓ 10%</UpDown>
        <UpDown>Yesterday</UpDown>
      </div>
    </ScoreBox>
    <Bar completed={83} height={24} />
    <Space height={41} />
    <ChartContainer>
      <Line />
    </ChartContainer>
  </Box>
);

export default performanceScore;
