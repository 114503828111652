import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import routes from "./routes";
import { ApolloProvider } from "@apollo/client";
import client from "./client";
import pageNotFound from "./pageNotFound";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./ui/globalStyle";
import ui from "./ui/index";
import * as Sentry from "@sentry/react";
import "./fonts/poppins/index.css";

const { default: Default } = ui.layout;

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DNS });

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={{ mode: "default" }}>
        <GlobalStyle />
        <Router>
          <Switch>
            {routes.map(({ component: Component, slug, children }) => (
              <Route
                key={slug}
                exact={slug === ""}
                path={`/${slug}`}
                render={({ match: { url } }) => (
                  <Switch>
                    <Route
                      path={`${url}`}
                      render={(props) => (
                        <Default {...props}>
                          <Component {...props} />
                        </Default>
                      )}
                      exact
                    />
                    {children?.map(({ slug, component: Component }) => (
                      <Route
                        key={slug}
                        path={`/${slug}`}
                        render={(props) => (
                          <Default {...props}>
                            <Component {...props} />
                          </Default>
                        )}
                        exact
                      />
                    ))}
                    <Route path="*" component={pageNotFound} />
                  </Switch>
                )}
              />
            ))}
            <Route path="*" component={pageNotFound} />
          </Switch>
        </Router>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
