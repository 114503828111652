import routes from "../../routes";
import styled from "styled-components";
import Item from "./item";
import { foreground } from "../theme";

const Box = styled.div`
  display: flex;
  background-color: ${foreground};
  box-shadow: rgb(0 0 0 / 75%) 0px 0px 33px -32px;
  border-right: 1px solid #e6ebef;
  height: 100vh;
  flex-direction: column;
  padding: 8px 16px;
`;

const sidebar = () => (
  <Box>
    {routes
      .filter((route) => route.showInMenu)
      .map(({ name, slug, children }) => (
        <Item key={slug} name={name} slug={slug} children={children} />
      ))}
  </Box>
);

export default sidebar;
