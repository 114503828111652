import styled from "styled-components";
import Employees from "./employees";
import Gender from "./gender";
import Age from "./age";
import Teams from "./teams";

const Box = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-auto-rows: max-content;

  @media (max-width: 1100px) {
    grid-template-columns 1fr 1fr;
  }
`;

const Overview = () => (
  <>
    <Box>
      <Employees />
      <Gender />
      <Age />
      <Teams />
    </Box>
  </>
);

export default Overview;
