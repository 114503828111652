import { ErrorBoundary } from "react-error-boundary";
import styled from "styled-components";
import WidgetError from "./widgetError";
import useDashboard from "./useDashboard";
import { widgets } from "./widgets/constants";
import Overview from "./overview";

const Container = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 100px;
  max-width: 1420px;

  @media (max-width: 1920px) {
    max-width: 1160px;
  }

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
`;

const Box = styled.div<{
  templateAreas: { desktop: string; tablet: string; mobile: string };
}>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-template-areas: ${(props) => props.templateAreas.desktop};
  grid-gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: ${(props) => props.templateAreas.tablet};
  }

  @media (max-width: 425px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: ${(props) => props.templateAreas.mobile};
  }
`;

const Title = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #333333;
`;

const Cell = styled.div<{ gridArea: string }>`
  grid-area: ${(props) => props.gridArea};
`;

const Dashboard = () => {
  const { data, gridTemplateAreas } = useDashboard();

  return (
    <Container>
      <div>
        <Title>Overview</Title>
        <Overview />
      </div>
      <div>
        <Title>Metrics</Title>
        <Box templateAreas={gridTemplateAreas}>
          {data
            .filter((cWidget) =>
              widgets.find((widget) => widget.type === cWidget.type)
            )
            .map((cWidget) => {
              const Component = widgets.find(
                (widget) => widget.type === cWidget.type
              )?.component as React.ElementType;

              return (
                // Key needs to use id from server
                <Cell key={cWidget.id} gridArea={`c${cWidget.id}`}>
                  <ErrorBoundary FallbackComponent={WidgetError}>
                    <Component />
                  </ErrorBoundary>
                </Cell>
              );
            })}
        </Box>
      </div>
    </Container>
  );
};

export default Dashboard;
