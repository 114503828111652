import styled from "styled-components";
import Box from "../../box";
import { Pie } from "react-chartjs-2";

const Count = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #333333;
  margin: 0;
`;

const ChartContainer = styled.div`
  height: 120px;
  position: absolute;
  right: -2px;
  bottom: 20px;
  width: 61px;
`;

const KeyValueContainer = styled.div`
  position: absolute;
  bottom: 24px;
`;

const Key = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #bebebe;
  display: block;
`;

const Value = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #333333;
  display: block;
`;

const data = {
  labels: ["Engineering", "Marketing", "Design", "Product"],
  datasets: [
    {
      data: [10, 6, 9, 10],
      backgroundColor: ["#9b87cc", "#b5a1e6", "#d4c3fa", "#e8d4ff"],
      borderWidth: 0,
    },
  ],
};

const options = {
  segmentShowStroke: false,
  rotation: 0.5 * Math.PI,
  circumference: 1 * Math.PI,
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    yAxes: [
      {
        stacked: true,
        display: false,
      },
    ],
    xAxes: [
      {
        stacked: true,
        display: false,
      },
    ],
  },
};

const Teams = () => (
  <Box minHeight={208} title="Teams">
    <Count>6</Count>
    <KeyValueContainer>
      <Key>Largest</Key>
      <Value>Engineering</Value>
    </KeyValueContainer>
    <ChartContainer>
      <Pie data={data} options={options} />
    </ChartContainer>
  </Box>
);

export default Teams;
