import Widget from "../Widget";
import performanceScore from "./performanceScore";
import eqScore from "./eqScore";
// import occupationalOverloadScore from "./occupationalOverloadScore";
// import burnoutStigmaScore from "./burnoutStigmaStore";
// import psychologicalSafetyScore from "./psychologicalSafetyScore";
import qaScore from "./qaScore";
import heartRateVaribility from "./heartRateVariability";

export const widgets: Widget[] = [
  { type: "PERFORMANCE_SCORE", component: performanceScore },
  // { type: "OCCUPATIONAL_OVERLOAD_SCORE", component: occupationalOverloadScore },
  // { type: "BURNOUT_STIGMA_SCORE", component: burnoutStigmaScore },
  // { type: "PSYCHOLOGICAL_SAFETY_SCORE", component: psychologicalSafetyScore },
  { type: "QA_SCORE", component: qaScore },
  { type: "EQ_SCORE", component: eqScore },
  { type: "HEART_RATE_VARIABILITY", component: heartRateVaribility },
];
