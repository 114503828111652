import { useState } from "react";
import WidgetSize from "../WidgetSize";
import WidgetType from "../WidgetType";
import getGridTemplateAreas from "./getGridTemplateAreas";

const useDashboard = () => {
  const [data] = useState<
    {
      id: string;
      type: WidgetType;
      size: WidgetSize;
    }[]
  >([
    { id: "1", type: "EQ_SCORE", size: "LARGE" },
    { id: "2", type: "HEART_RATE_VARIABILITY", size: "MEDIUM" },
    { id: "3", type: "PERFORMANCE_SCORE", size: "SMALL" },
    { id: "4", type: "QA_SCORE", size: "LARGE" },
  ]);

  const [gridTemplateAreas] = useState({
    desktop: getGridTemplateAreas({
      idSize: data.map(({ id, size }) => ({ id, size })),
      columns: 3,
    }),
    tablet: getGridTemplateAreas({
      idSize: data.map(({ id, size }) => ({ id, size })),
      columns: 2,
      resize: 1,
    }),
    mobile: getGridTemplateAreas({
      idSize: data.map(({ id, size }) => ({ id, size })),
      columns: 1,
      resize: 1,
    }),
  });

  return {
    data,
    gridTemplateAreas: gridTemplateAreas,
  };
};

export default useDashboard;
