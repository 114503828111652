import { useState } from "react";
import Box from "../../box";

const QaScore = () => {
  const [state, setState] = useState<boolean>(false);

  if (state) throw Error("Something went wrong");

  return (
    <Box title="QA Score">
      <button onClick={() => setState(!state)}>Click me</button>{" "}
      <p>
        This will break the widget however the error will be isolated to this
        widget and not break anything else
      </p>
    </Box>
  );
};

export default QaScore;
