import { RouteComponentProps } from "react-router-dom";
import dashboard from "./loggedIn/dashboard";
import settings from "./loggedIn/settings";
import teams from "./loggedIn/teams";

export type Route = {
  name: string;
  slug: string;
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  children?: Route[] | null;
  showInMenu?: boolean;
};

const routes: Route[] = [
  {
    name: "Dashboard",
    slug: "",
    component: dashboard,
    showInMenu: true,
    children: null,
  },
  {
    name: "Teams",
    slug: "teams",
    component: teams,
    showInMenu: true,
    children: null,
  },
  {
    name: "Settings",
    slug: "settings",
    component: settings,
    showInMenu: true,
    children: null,
  },
];

export default routes;
