import styled from "styled-components";

const Box = styled.div<{ height: number }>`
  position: relative;
  height: ${(props) => props.height}px;
`;

const GroundBar = styled.div<{
  color: string;
  width?: number;
  height: number;
  top?: boolean;
}>`
  background-color: ${(props) => props.color};
  height: ${(props) => props.height}px;
  width: ${(props) => (props.width ? props.width : 100)}%;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  ${(props) => (props.top ? "z-index: 1;" : null)}
`;

const Bar = ({
  backgroundColor = "#F2F2F2",
  foregroundColor = "#4e3d85",
  completed,
  height = 10,
}: {
  backgroundColor?: string;
  foregroundColor?: string;
  completed: number;
  height?: number;
}) => (
  <Box height={height}>
    <GroundBar height={height} color={foregroundColor} width={completed} top />
    <GroundBar height={height} color={backgroundColor} />
  </Box>
);

export default Bar;
