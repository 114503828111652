import theme from "styled-theming";

export const primary = theme("mode", {
  default: "#7b68ac",
  dark: "black",
});

export const secondary = theme("mode", {
  default: "#bd6aab",
  dark: "red",
});

export const tertiary = theme("mode", {
  default: "white",
  dark: "black",
});

export const title = theme("mode", {
  default: "#4e3d85",
  dark: "red",
});

export const text = theme("mode", {
  default: "black",
  dark: "white",
});

export const background = theme("mode", {
  default: "#f5f8fa",
  dark: "#1e1e1e",
});

export const foreground = theme("mode", {
  default: "white",
  dark: "black",
});
