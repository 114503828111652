import styled from "styled-components";
import { text } from "../../../../ui/theme";
import Box from "../../box";
import Line from "../../line";

const ChartContainer = styled.div`
  height: 24px;
`;

const Score = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  margin: 0;
  color: #333333;
`;

const UpDown = styled.span`
  color: ${text};
  opacity: 0.4;
  font-size: 13px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: block;
`;

const Employees = () => (
  <Box minHeight={208} title="Employees">
    <Score>69</Score>
    <UpDown>↑ 3%</UpDown>
    <UpDown>Last Week</UpDown>
    <ChartContainer>
      <Line />
    </ChartContainer>
  </Box>
);

export default Employees;
