import { Link } from "react-router-dom";
import styled from "styled-components";
import images from "../../images";
import { text } from "../theme";

const { headerLogo } = images;

const Logo = styled.img`
  max-height: 100%;
  width: auto;
`;

const Box = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  box-shadow: 0px 10px 15px -20px rgb(0 0 0 / 75%);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 3;
  padding: 6px 16px;
  box-sizing: border-box;
`;

const Branding = styled(Link)`
  display: flex;
  align-items: center;
  color: ${text};
  text-decoration: none;
`;

const Title = styled.h1`
  margin: 0 0 0 8px;
  font-size: 14px;
  font-weight: normal;
`;

const header = () => (
  <Box>
    <Branding to={"/"}>
      <Logo src={headerLogo} alt="Regenemm Corporate" />
      <Title>Regenemm Corporate</Title>
    </Branding>
  </Box>
);

export default header;
