import Box from "../../box";
import { HorizontalBar } from "react-chartjs-2";
import styled from "styled-components";

const Percents = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Percent = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #333333;
  margin: 0;
`;

const Label = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  display: block;
`;

const ChartContainer = styled.div`
  height: 30px;
  border-radius: 9px;
  overflow: hidden;
  position: absolute;
  bottom: 24px;
  width: calc(100% - 48px);
`;

const data = {
  labels: ["Gender"],
  datasets: [
    {
      label: "Males %",
      data: [40],
      backgroundColor: "#4e3d85",
    },
    {
      label: "Females %",
      data: [60],
      backgroundColor: "#b5a1e6",
    },
  ],
};

const options = {
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    yAxes: [
      {
        stacked: true,
        display: false,
      },
    ],
    xAxes: [
      {
        stacked: true,
        display: false,
      },
    ],
  },
};

const Gender = () => (
  <Box minHeight={208} title="Gender Ratio">
    <Percents>
      <div>
        <Percent>40%</Percent>
        <Label>Male</Label>
      </div>
      <div>
        <Percent>60%</Percent>
        <Label>Female</Label>
      </div>
    </Percents>
    <ChartContainer>
      <HorizontalBar data={data} options={options} />
    </ChartContainer>
  </Box>
);

export default Gender;
