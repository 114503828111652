import layout from "./layout";
import bar from "./bar";
import space from "./space";

const ui = {
  layout,
  bar,
  space,
};

export default ui;
