import { createGlobalStyle } from "styled-components";
import { background } from "./theme";

const globalStyle = createGlobalStyle`
    body {
        background-color: ${background};
        margin: 0;
        font-family: 'Poppins', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
  
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
    }
`;

export default globalStyle;
